import { FetchState } from '@/types/fetchState'

export const fetchStatePromiseWrapper = async <T>(
  fetchState: FetchState,
  promise: Promise<T>
): Promise<T> => {
  fetchState.pending = true

  try {
    const result = await promise
    fetchState.timestamp = new Date().getTime()
    return result
  } catch (error) {
    fetchState.error = error as Error
    throw error
  } finally {
    fetchState.pending = false
  }
}
