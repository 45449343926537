import { createI18n } from 'vue-i18n'

import defaultMessage from './messages/default.json'

export const messageI18n = createI18n({
  legacy: false,
  locale: 'default',
  fallbackLocale: 'default',
  messages: {
    default: defaultMessage,
  },
  globalInjection: true,
})
