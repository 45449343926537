import { api } from '@/api'
import { EventInterface, EventType } from '@/types/event'
import { LocalDate, toLocalDate } from '@/types/localDate'
import { SignUpRecordUnsaved } from '@/types/signUpRecord'
import { Country } from '@/types/country'
import { defineStore } from 'pinia'
import { useTestingStore } from './testing'
import { FetchState } from '@/types/fetchState'
import { Office } from '@/types/office'
import { SourceType } from '@/types/sourceType'
import { fetchStatePromiseWrapper } from '@/util/fetchStatePromiseWrapper'
import { fallbackCountries } from '@/config/fallbackCountries'

const DEFAULT_OFFICE = 'au'
const DEFAULT_COUNTRY = 'au'
const SIGN_UP_ENDPOINT = 'connect.creation.com'

export const useMainStore = defineStore({
  id: 'main',

  state(): {
    uuidSignUpRecord: string | null
    givenName: string | null
    familyName: string | null
    postcode: string | null
    email: string | null
    wantsInfobytes: boolean
    subscribedToInfobytes: boolean
    socialMedia: string[]
    source: SourceType | null
    event?: EventInterface | null
    data: Record<string, any>

    countryFromIp: string | null

    countrySetByUser: string | null
    officeSetByUser: string | null

    countries: Record<string, Country>
    countriesFetchState: FetchState
    events: EventInterface[]
    eventsFetchState: FetchState
  } {
    return {
      uuidSignUpRecord: null,
      givenName: null,
      familyName: null,
      postcode: null,

      email: null,
      wantsInfobytes: false,
      subscribedToInfobytes: false,
      socialMedia: [],
      source: null,
      event: null,
      data: {},

      // The country determined from the IP address.
      countryFromIp: null,

      countrySetByUser: null,
      officeSetByUser: null,

      countries: fallbackCountries,
      events: [],
      countriesFetchState: {
        pending: false,
        error: null,
        timestamp: 0,
      },
      eventsFetchState: {
        pending: false,
        error: null,
        timestamp: 0,
      },
    }
  },

  getters: {
    ready(): boolean {
      return (
        Object.keys(this.countries).length !== 0 &&
        (this.events.length !== 0 || this.eventsFetchState.timestamp !== 0)
      )
    },

    userEnteredEvent(): string {
      return this.source === SourceType.Event
        ? this.event?.type === EventType.UserEntered
          ? this.event.location
          : ''
        : ''
    },

    localDate(): LocalDate {
      const testingStore = useTestingStore()

      return testingStore.enabled ? testingStore.localDate : toLocalDate(new Date())
    },

    country(): string {
      return this.countrySetByUser || this.countryFromIp || DEFAULT_COUNTRY
    },

    office(): Office {
      return this.countries[this.country]?.officeCode || DEFAULT_OFFICE
    },

    signUpRecord(): SignUpRecordUnsaved {
      return {
        uuidSignUpRecord: this.uuidSignUpRecord ?? undefined,
        endpoint: SIGN_UP_ENDPOINT,
        office: this.office,
        givenName: this.givenName,
        familyName: this.familyName,
        postcode: this.postcode,
        country: this.country,
        email: this.email,
        socialMedia: this.socialMedia,
        source: this.source,
        event: this.event,
        data: this.data,
      }
    },
  },

  actions: {
    async saveRecord() {
      const signUpRecord = await api.saveRecord(this.signUpRecord)

      this.uuidSignUpRecord = signUpRecord.uuidSignUpRecord
    },

    async subscribeToInfobytes() {
      this.wantsInfobytes = true

      const signUpRecord = await api.subscribeRecordToInfobytes(this.signUpRecord)

      this.uuidSignUpRecord = signUpRecord.uuidSignUpRecord
      this.subscribedToInfobytes =
        !!signUpRecord.data?.actions?.fulfilled.includes('subscribe:infobytes')
    },

    async reset() {
      await this.$reset()
    },

    async init() {
      if (this.ready) return

      // No await as the most likely countries (CMI office countries) already have been filled with `fallbackCountries`
      // This is more for people outside of CMI office countries
      this.fetchCountries()

      try {
        this.countryFromIp = await api.getCountryFromIp()
      } catch (error) {
        console.warn(`Unable to get country from IP.`)
      }

      await this.fetchEvents()
    },

    setCountry(country: string | null) {
      this.countrySetByUser = country
    },

    setSourceFromEvent(event: EventInterface) {
      this.source = SourceType.Event
      this.event = event
    },

    skipSource() {
      this.source = null
    },

    notEventSource() {
      this.source = SourceType.NotEvent
    },

    async fetchCountries() {
      this.countries = await fetchStatePromiseWrapper(
        this.countriesFetchState,
        api.fetchCountries()
      )
    },

    async fetchEvents() {
      this.events = await fetchStatePromiseWrapper(
        this.eventsFetchState,
        api.fetchEvents(this.office, this.localDate)
      )
    },
  },
})
