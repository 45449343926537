import { defineStore } from 'pinia'

export enum ProgressSteps {
  Event = 'event',
  Infobytes = 'infobytes',
  SocialMedia = 'socialMedia',
}

export const useProgressStore = defineStore({
  id: 'progress',

  state: () => ({
    event: false,
    infobytes: false,
    socialMedia: false,
  }),

  getters: {
    nextStep: (state) => {
      if (!state.event) return ProgressSteps.Event
      if (!state.infobytes) return ProgressSteps.Infobytes
      if (!state.socialMedia) return ProgressSteps.SocialMedia

      return ProgressSteps.Event
    },
  },
})
