export enum SocialMediaPlatform {
  Facebook = 'facebook',
  Instagram = 'instagram',
  Twitter = 'twitter',
  Youtube = 'youtube',
}

export interface SocialMediaAccount {
  id: string
  platform: SocialMediaPlatform
  link: string
  label: string
}
