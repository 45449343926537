import Index from '@/pages/index.vue'
import Infobytes from '@/pages/infobytes.vue'
import SocialMedia from '@/pages/social-media.vue'
import OtherEvent from '@/pages/other-event.vue'
import ThankYou from '@/pages/thank-you.vue'

import { Pinia } from 'pinia'
import { useProgressStore } from '@/stores/progress'
import { RouteLocationNormalized } from 'vue-router'
import { predefinedEventsConfig } from '@/config/predefinedEvents'
import { useMainStore } from '@/stores/main'

export const createRoutes = (pinia: Pinia) => {
  const checkForPredefinedEvent = (to: RouteLocationNormalized): void | string => {
    const predefinedEventAlias = to.query.event?.toString()
    if (predefinedEventAlias) {
      const predefinedEvent = predefinedEventsConfig[predefinedEventAlias]
      if (predefinedEvent) {
        const mainStore = useMainStore(pinia)
        const progressStore = useProgressStore(pinia)

        mainStore.setSourceFromEvent(predefinedEvent)
        progressStore.event = true

        return '/infobytes'
      }
    }
  }

  const requireEvent = () => {
    const progressStore = useProgressStore(pinia)

    if (!progressStore.event) return '/'
  }

  const requireInfoBytes = () => {
    const progressStore = useProgressStore(pinia)

    if (!progressStore.infobytes) return '/infobytes'
  }

  const requireSocialMedia = () => {
    const progressStore = useProgressStore(pinia)

    if (!progressStore.socialMedia) return '/social-media'
  }

  return [
    {
      path: '/',
      name: 'index',
      component: Index,
      beforeEnter: checkForPredefinedEvent,
      meta: {
        slidePosition: 0,
        showProgress: true,
        showBack: false,
        showVersion: true,
        showCountryPicker: true,
      },
    },
    {
      path: '/other-event',
      name: 'other-event',
      component: OtherEvent,
      meta: {
        slidePosition: 1,
        showProgress: true,
        showBack: true,
        showVersion: true,
        showCountryPicker: true,
      },
    },
    {
      path: '/infobytes',
      name: 'infobytes',
      component: Infobytes,
      beforeEnter: requireEvent,
      meta: {
        slidePosition: 2,
        showProgress: true,
        showBack: true,
        showVersion: true,
        showCountryPicker: true,
      },
    },
    {
      path: '/social-media',
      name: 'social-media',
      component: SocialMedia,
      beforeEnter: [requireEvent, requireInfoBytes],
      meta: {
        slidePosition: 3,
        showProgress: true,
        showBack: true,
        showVersion: true,
        showCountryPicker: true,
      },
    },
    {
      path: '/thank-you',
      name: 'thank-you',
      component: ThankYou,
      beforeEnter: [requireEvent, requireInfoBytes, requireSocialMedia],
      meta: {
        slidePosition: 4,
        showProgress: false,
        showBack: false,
        showVersion: false,
        showCountryPicker: false,
      },
    },
  ]
}
