import { Office } from '@/types/office'
import { SocialMediaAccount } from '@/types/socialMediaPlatform'
import { mergeConfig } from '@/util/mergeConfig'
import { socialMediaAccounts } from './socialMediaAccounts'

export interface OfficeI18n {
  socialMedia: SocialMediaAccount[]
  thankYou: {
    displayCreationMagazineButton: boolean
  }
}

const defaultConfig: OfficeI18n = {
  socialMedia: [
    socialMediaAccounts.instagram,
    socialMediaAccounts.youtube,
    socialMediaAccounts.facebook,
    socialMediaAccounts.twitter,
  ],
  thankYou: {
    displayCreationMagazineButton: false,
  },
}

const officeConfig: Record<Office, Partial<OfficeI18n>> = {
  au: {
    thankYou: {
      displayCreationMagazineButton: true,
    },
  },
  us: {},
  ca: {
    socialMedia: [
      socialMediaAccounts.instagramCa,
      socialMediaAccounts.youtubeCa,
      socialMediaAccounts.facebook,
      socialMediaAccounts.twitter,
    ],
  },
  uk: {},
  nz: {},
  za: {},
  sg: {
    thankYou: {
      displayCreationMagazineButton: true,
    },
  },
}

export const officeI18n = (office: Office): OfficeI18n => {
  const config = officeConfig[office]
  return config ? mergeConfig(config, defaultConfig) : defaultConfig
}
