import { EventInterface } from '@/types/event'

export const predefinedEventsConfig: Record<string, EventInterface> = {
  // gicf: {
  //   location: 'Guangzhou International Christian Fellowship',
  //   speaker: 'Scot Devlin',
  //   time: '10.00am (China time - 12.00pm AEST)',
  //   speakerImage: 'https://dl0.creation.com/assets/bios/minsdevlin@68h.webp',
  //   office: 'au',
  //   date: '2021-08-08',
  //   id: '210706251622321090027O',
  //   type: EventType.Scheduled,
  // },
}
