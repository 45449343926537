import { useMainStore } from '@/stores/main'
import { Context } from '@/types/context'
import { PostcodeConfig } from '@/types/postcodeConfig'
import { Pinia } from 'pinia'
import { computed, ComputedRef, toRef, watch } from 'vue'
import { messageI18n } from './messageI18n'
import { postcodeI18n } from './postcodeI18n'
import { officeI18n, OfficeI18n } from './officeI18n'

export const initializeI18n = ({ app, pinia }: Context): void => {
  app.use(messageI18n)

  const mainStore = useMainStore(pinia)

  app.config.globalProperties.$i18n.locale = mainStore.office

  watch(toRef(mainStore, 'office'), () => {
    app.config.globalProperties.$i18n.locale = mainStore.office
  })
}

export const usePostcodeI18n = (pinia?: Pinia): ComputedRef<PostcodeConfig> => {
  return computed(() => {
    const mainStore = useMainStore(pinia)

    return postcodeI18n(mainStore.country)
  })
}

export const useOfficeI18n = (pinia?: Pinia): ComputedRef<OfficeI18n> => {
  return computed(() => {
    const mainStore = useMainStore(pinia)

    return officeI18n(mainStore.office)
  })
}
