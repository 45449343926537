import { Country } from '@/types/country'

// Fallback object of CMI Office countries if Geo service is unreachable
// AND we speed up the initial load by not waiting for countries before loading events
// TODO: It would make sense to have `@cmiteam/countries` package to have has a fallback around.

export const fallbackCountries: Record<string, Country> = {
  au: {
    code: 'au',
    officeCode: 'au',
    name: 'Australia',
  },

  us: {
    code: 'us',
    officeCode: 'us',
    name: 'United States',
  },
  gb: {
    code: 'gb',
    officeCode: 'uk',
    name: 'United Kingdom',
  },

  za: {
    code: 'za',
    officeCode: 'za',
    name: 'South Africa',
  },

  sg: {
    code: 'sg',
    officeCode: 'sg',
    name: 'Singapore',
  },

  nz: {
    code: 'nz',
    officeCode: 'nz',
    name: 'New Zealand',
  },

  ca: {
    code: 'ca',
    officeCode: 'ca',
    name: 'Canada',
  },
}
