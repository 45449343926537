import { PostcodeConfig } from '@/types/postcodeConfig'

const postcodeConfigs: Record<string, PostcodeConfig> = {
  au: {
    label: 'Postcode',
    placeholder: '0000',
    pattern: /^[0-9]{4}$/,
    inputmode: 'numeric',
    title: 'Please ensure postcode is 4 digits long and contains no letters or spaces.',
    required: true,
  },
  ca: {
    label: 'Postcode',
    placeholder: 'A1A 1A1',
    pattern: /^[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/,
    title: 'Please ensure postcode matches the format A1A 1A1',
    required: true,
    serializer: (value) => value.replace(/ /g, '').toUpperCase(), // Removes extra space and makes the postcode uppercase
  },
  nz: {
    label: 'Postcode',
    placeholder: '0000',
    pattern: /^[0-9]{4}$/,
    inputmode: 'numeric',
    title: 'Please ensure postcode is 4 digits long and contains no letters or spaces.',
    required: true,
  },
  gb: {
    label: 'Postcode',
    placeholder: 'AA9 9AA',
    pattern:
      /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/,
    title: 'Please ensure postcode matches the format AA9 9AA',
    required: true,
  },
  us: {
    label: 'ZIP Code',
    placeholder: '00000',
    pattern: /^\d{5}(-\d{4})?$/,
    title: 'Please ensure the ZIP code is 5 digits long and contains no letters or spaces.',
    required: true,
  },
  za: {
    label: 'Postcode',
    placeholder: '0000',
    pattern: /^[0-9]{4}$/,
    inputmode: 'numeric',
    title: 'Please ensure postcode is 4 digits long and contains no letters or spaces.',
    required: true,
  },
  sg: {
    label: 'Postcode',
    placeholder: '00000',
    pattern: /^[0-9]{6}$/,
    inputmode: 'numeric',
    title: 'Please ensure postcode is 6 digits long and contains no letters or spaces.',
    required: true,
  },
  default: {
    label: 'Postcode',
    placeholder: '0000',
    required: false,
  },
}

export const postcodeI18n = (countryCode: string): PostcodeConfig => {
  return postcodeConfigs[countryCode] || postcodeConfigs.default
}
