import { LocalDate, toLocalDate } from '@/types/localDate'
import { Office } from '@/types/office'
import { defineStore } from 'pinia'

export const useTestingStore = defineStore({
  id: 'testing',

  state(): {
    enabled: boolean
    office: Office
    localDate: LocalDate
  } {
    return {
      enabled: false,
      office: 'au',
      localDate: toLocalDate(new Date()),
    }
  },

  getters: {},

  actions: {},
})
