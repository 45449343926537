import { createPinia } from 'pinia'
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import App from './App.vue'
import './index.css'
import { createRoutes } from './routes'
import { initializeSentry } from './sentry'
import { useTestingStore } from './stores/testing'
import { Context } from './types/context'
import { initializeI18n } from './i18n'
import { initializeTestingApi } from './testingApi'
import { initializeGoogleAnalytics } from './googleAnalytics'
import { initializeStores } from './stores'

const app = createApp(App)

const pinia = createPinia()

const router = createRouter({
  history: createWebHistory(),
  routes: createRoutes(pinia),
})

const context: Context = { app, pinia, router }

initializeStores(context)

initializeI18n(context)

// Initialize TestingApi in test mode
if (import.meta.env.MODE === 'test' || !import.meta.env.PROD) initializeTestingApi(context)

// Initialize Sentry in production
if (import.meta.env.PROD) initializeSentry(context)

// Initialize Google Analytics if ID set
if (import.meta.env.VITE_GA_MEASUREMENT_ID) initializeGoogleAnalytics(context)

// Determine transition direction
router.afterEach((to, from) => {
  to.meta.back = (to.meta.slidePosition as number) < (from.meta.slidePosition as number)
})

// Enable test mode if ?test is present
router.beforeEach((to) => {
  const testingStore = useTestingStore(pinia)
  testingStore.enabled = to.query.test !== undefined
})

app.use(router)

app.mount('#app')
