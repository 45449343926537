import VueGtag from 'vue-gtag'
import { Context } from './types/context'

export function initializeGoogleAnalytics({ app, router }: Context): void {
  app.use(
    VueGtag,
    {
      config: {
        id: import.meta.env.VITE_GA_MEASUREMENT_ID,
      },
    },
    router
  )
}
