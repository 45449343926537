// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isObject(val: any) {
  return val !== null && typeof val === 'object'
}

/**
 * Deep merges 2 objects where the left hand side one has higher priority.
 *
 * @param config The config overrides
 * @param defaultConfig The default config
 * @returns The merged config
 */
export function mergeConfig<T>(config: Partial<T>, defaultConfig: T): T {
  const resultingConfig: T = Object.assign({}, defaultConfig)

  for (const key in config) {
    const val = config[key]

    if (isObject(val) && isObject(resultingConfig[key])) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      resultingConfig[key] = mergeConfig(val!, resultingConfig[key])
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      resultingConfig[key] = val!
    }
  }

  return resultingConfig
}
