import { Office } from './office'

export enum EventType {
  Scheduled = 'scheduled',
  UserEntered = 'user-entered',
}

// Has to be named EventInterface to not conflict with Event
export interface EventInterface {
  location: string
  speaker?: string
  time?: string
  speakerImage?: string
  office?: Office
  date?: string
  id: string | null
  type: EventType
}

export interface EventFromCreationDB {
  id: string
  office: Office
  startdate: string
  starttime: string
  eventtype: string
  company: string
  address: string
  city: string
  region: string
  country: string
  speaker: string
  speakerimage: string
  tstmp: string
}
