import { computed, WritableComputedRef, Ref } from 'vue'

/**
 * Uses computed to wrap a nullable string property `string | null | undefined`
 * so that it returns an empty string for falsy values
 * and sets the property to null for falsy values.
 *
 * @param {Ref} nullableStringRef - The ref to the nullable string property
 */
export const nullableStringWrapper = (
  nullableStringRef: Ref<string | null | undefined>
): WritableComputedRef<string> => {
  return computed({
    get() {
      return nullableStringRef.value || ''
    },

    set(value: string) {
      nullableStringRef.value = value || null
    },
  })
}
