import { SocialMediaAccount, SocialMediaPlatform } from '@/types/socialMediaPlatform'

const facebook: SocialMediaAccount = {
  id: 'facebook',
  platform: SocialMediaPlatform.Facebook,
  link: 'https://www.facebook.com/creationministries',
  label: '@creationministries',
}

const youtube: SocialMediaAccount = {
  id: 'youtube',
  platform: SocialMediaPlatform.Youtube,
  link: 'https://www.youtube.com/user/creationclips',
  label: 'Creation Ministries International',
}

const instagram: SocialMediaAccount = {
  id: 'instagram',
  platform: SocialMediaPlatform.Instagram,
  link: 'https://www.instagram.com/creationministries/',
  label: '@creationministries',
}

const twitter: SocialMediaAccount = {
  id: 'twitter',
  platform: SocialMediaPlatform.Twitter,
  link: 'https://twitter.com/creationnews',
  label: '@creationnews',
}

const youtubeCa: SocialMediaAccount = {
  id: 'youtubeCa',
  platform: SocialMediaPlatform.Youtube,
  link: 'https://www.youtube.com/user/CMIcreationstation',
  label: 'CMI Video',
}

const instagramCa: SocialMediaAccount = {
  id: 'instagramCa',
  platform: SocialMediaPlatform.Instagram,
  link: 'https://www.instagram.com/cmicanada/',
  label: '@cmicanada',
}

export const socialMediaAccounts = {
  facebook,
  youtube,
  instagram,
  twitter,
  youtubeCa,
  instagramCa,
}
